import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import integration from "../images/svg/undraw_Integration.svg";
import javascriptFrameworks from "../images/svg/undraw_JavaScript_frameworks.svg";
import programming from "../images/svg/undraw_programming.svg";
import mobileApps from "../images/svg/undraw_mobile_apps.svg";

const ServicesPage = () => (
    <Layout>
        <SEO title="Palvelut" description={'Tutstu tarkemmin palvelutarjontaan täältä. Full-stack, Verkkosovellukset, Integroinnit, Mobiilisovellukset.'} />
        <div className="services-page">
            <div className="content">
                <h1>Palvelut</h1>

                <div className="columns">
                    <div className="column">
                        <h4>Full-stack suunnittelu ja toteutus (ohjelmointi)</h4>
                        <img src={programming} alt={'Ohjelmointi'} style={{ width: '330px', marginBottom: '4rem' }} />
                        <p>Jos tarvitset avaimet käteen palvelun, niin minä suunnittelen ja toteutan koko ympäristön sisältäen palvelin, pilvipalvelut, tietokannat ja asiakas sovelluksen toteutuksen.</p>
                        <p>Tai jos tarvitset ohjelmointia vain alihankintana, niin ota yhteyttä.</p>
                        {/*
                        <Link to="/">Lue lisää...</Link>
                        */}
                    </div>
                    <div className="column">
                        <h4>Verkkosovellukset ja verkkosivut</h4>
                        <img src={javascriptFrameworks} alt={'Javascript Frameworks'}/>
                        <p>SoftTile tuottaa verkkosivuprojektit avaimet käteen -periaattella projektin alusta loppuun. Autan projektin määrittelyssä, käyttöönotosta aina ylläpitoon asti.</p>
                        <p>Säästöä ja tuloksia saavutetaan, kun työhön valitaan aina siihen parhaiten sopiva teknologia.</p>
                        {/*
                        <Link to="/">Lue lisää...</Link>
                        */}
                    </div>
                    <div className="column">
                        <h4>Integroinnit</h4>
                        <img src={integration} alt={'Integroinnit'} style={{ transform: 'scale(-1,1)', msTransform: 'scale(-1,1)', width: '216px' }}/>
                        <p>Toimiiko nykyinen sovelluksesi/palvelusi osana suurempaa kokonaisuutta, jossa tarvitaan integrointeja muihin järjestelmiin? Vai tarvitsetko uusia rajapintoja nykyiseen integraatioosi?</p>
                        <p>Autan sinua mielelläni integrointiasioissa.</p>
                        {/*
                        <Link to="/">Lue lisää...</Link>
                        */}
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <h4>Mobiilisovellukset</h4>
                        <img src={mobileApps} alt={'Mobiilisovellukset'} style={{ width: '330px', marginBottom: '4rem' }} />
                        <p>Autan sinua valitsemaan yrityksellesi tarpeiden mukaisen vaihtoehdon ja valmistan tarvitsemasi mobiilisovelluksen avaimet käteen periaatteella.
                            Teknisen toteutuksen lisäksi voin auttaa sinua käyttöliittymä- ja käyttäjäkokemuksen suunnittelussa.</p>
                        <h5>Huom! Tässä selaillessa oletko huomannut, että tämäkin sivusto on mobiililaitteeseen asennettava PWA-sovellus?</h5>
                        {/*
                        <Link to="/">Lue lisää...</Link>
                        */}
                    </div>
                </div>

                {/*
                <div className="columns">
                    <div className="column">
                        <h4>Verkkosovellukset ja verkkosivut</h4>
                        <p>SoftTile tuottaa verkkosivuprojektit avaimet käteen -periaattella projektin alusta loppuun. Autan projektin määrittelyssä, käyttöönotosta aina ylläpitoon asti.</p>
                        <p>Työhön valitaan aina siihen parhaiten sopiva teknologia.</p>
                    </div>
                    <div className="column">
                        <img src={javascriptFrameworks} alt={'Javascript Frameworks'} />
                    </div>
                </div>
                <div className="columns" style={{ backgroundColor: '#f6f5f5'}}>
                    <div className="column">
                        <img src={integration} alt={'Ingeration'} />
                    </div>
                    <div className="column">
                        <h4>Integroinnit</h4>
                        <p>Toimiiko nykyinen sovelluksesi/palvelusi osana suurempaa kokonaisuutta, jossa tarvitaan integrointeja muihin järjestelmiin? Vai tarvitsetko uusia rajapintoja nykyiseen integraatioosi?</p>
                        <p>Autan sinua mielelläni integrointiasioissa.</p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h4>Full-stack suunnitelu ja toteutus (Ohjelmointi)</h4>
                    </div>
                    <div className="column">
                        <img src={programming} alt={'Programming'} />
                    </div>
                </div>
*/}
                {/*
                        <Link to="/">Lue lisää...</Link>
                 */}


            </div>
        </div>
    </Layout>
)

export default ServicesPage
